<template lang="pug">
.container-fluid.p-5(style="min-height: 65vh;")
    h3.m-4.px-5 Sorry, deze pagina bestaat niet...
    .m-5.px-5
        router-link(
            to="/"
        )
            .btn.btn-large.btn-outline-success.x-btn-background-light
                span &lt;&lt; &nbsp;
                span Terug naar de homepage
</template>

<script>
export default {
    name : 'NotFound'
}
</script>
